/* eslint-disable react/jsx-pascal-case*/
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../components/common/styles/customCarousel.css';
import { useArticles, useCarousel, useOpenContactModal, useViewport } from '../hooks';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import Video from '../components/ui/Video';
import ShuffledSectionMobile from '../components/common/ShuffledSectionMobile';
import ShuffledSection from '../components/common/ShuffledSection';
import { UpcomingEventsBanner } from '../components/events/UpcomingEventsBanner';
import styles from './styles/newHome.module.scss';
import techStyles from './styles/newTechnology.module.scss';
import Banner from '../images/index/BannerWithoutBg.svg';
import homeAnimationCropped from '../images/index/2024/hero-desktop-wide_5_cropped.mp4';
import homeAnimationMobile from '../images/index/2024/home-mobile-3.mp4';
import heroStyles from './styles/homeHero.module.scss';
import HighlightedArticlesCarousel from '../components/common/HighlightedArticlesCarousel';
import { GTM_KEYS } from '../constants/googleTagManagerKeys';
import { MODAL_SOURCE_BUTTONS } from '../constants/modal';
import Customers from '../components/common/Customers';

const { HOME: { BOOK_DEMO_BUTTON } } = GTM_KEYS;
const { BOOK_DEMO_HOME } = MODAL_SOURCE_BUTTONS;

const Index = () => {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;
    const isMobile = width < breakpoint;

    const scrollElementReference = useRef(null);

    const openContactModal = useOpenContactModal(BOOK_DEMO_HOME);

    const carouselDataArray = useCarousel();

    const articleData = useArticles();
    const recentArticles = articleData.slice(0, 4);
    const highlightedArticles = articleData.filter(item => item.featuredOnHomeCarousel);

    const indexImages = useStaticQuery(graphql `
        query IndexImages {
            banner: file(relativePath : { eq: "index/2024/2024-hero-desktop-still.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1772
                        placeholder: NONE
                    )
                }
            },
            bannerMobile: file(relativePath : { eq: "index/2024/2024-hero-mobile-still.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 480
                        placeholder: NONE
                    )
                }
            }
        }
    `);

    const imageMain = indexImages.banner.childImageSharp.gatsbyImageData;
    const imageMainMobile = indexImages.bannerMobile.childImageSharp.gatsbyImageData;

    return (
        <React.Fragment>
            <Layout isHome title='Witbe'>
                <SEO
                    title='Witbe'
                    ogDescription='Trusted by hundreds of customers, Witbe is the best tool for testing and monitoring your video streaming quality of experience in the market'
                    description='Trusted by hundreds of customers, Witbe is the best tool for testing and monitoring your video streaming quality of experience in the market'
                />
                <Helmet>
                    <link rel='preload' href={Banner} as='image' type='image/svg+xml' />
                </Helmet>
                <div id={heroStyles.mainImageWrapper}>
                    <div className={heroStyles.mainImageTextWrapper}>
                        <div className={heroStyles.mainImageLeft}>
                            <div className={heroStyles.mainImageContent}>
                                <h1 className={heroStyles.mainImageTitle}>
                                    {t('siteMetadata.home2023.deliver')}
                                </h1>
                                <p className={heroStyles.mainImageSubheader}>{t('siteMetadata.home2023.test')}</p>
                                <p className={heroStyles.mainImageSub}>{t('siteMetadata.home2023.offers')}</p>
                                <div
                                    className={
                                        isMobile
                                            ? techStyles.learnMoreBuyButtons
                                            : styles.learnMoreBuyButtons
                                    }
                                >
                                    <Link
                                        to='/'
                                        className={
                                            isMobile
                                                ? `${techStyles.blueButtonMobile} ${styles.buyButton}`
                                                : styles.blueButtonBigger
                                        }
                                        onClick={openContactModal}
                                        data-gtm-id={BOOK_DEMO_BUTTON}
                                    >
                                        {t('siteMetadata.home2023.book')}
                                    </Link>
                                    <div className={!isMobile && styles.cardLearnMoreInlineContainer}>
                                        <AnchorLink
                                            to='/#usecase'
                                            className={
                                                isMobile
                                                    ? heroStyles.cardLearnMoreInline
                                                    : styles.cardLearnMoreInlineBigger
                                            }
                                        >
                                            {t('siteMetadata.home2023.see')}
                                        </AnchorLink>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={isMobile ? heroStyles.chevronRight : styles.chevronRight}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={heroStyles.mainImageRight}>
                            <div className={styles.animateWrapperMobile}>
                                <GatsbyImage
                                    image={imageMainMobile}
                                    alt='Witbe animation'
                                    // className={styles.gatsbyImgWrapper}
                                    className={styles.placeholderWrapper}
                                />
                                <Video
                                    isDecorative
                                    video={homeAnimationMobile}
                                    poster='https://www.witbe.net/assets2024-hero-mobile-still.jpg'
                                />
                            </div>
                            <div className={styles.animateWrapperDesktop}>
                                <GatsbyImage
                                    image={imageMain}
                                    alt='Witbe animation'
                                    // className={styles.gatsbyImgWrapper}
                                    className={styles.placeholderWrapper}
                                />
                                {/* <img src={placeholder} alt='Witbe animation' className={styles.placeholder} /> */}
                                <Video
                                    isDecorative
                                    width='100%'
                                    video={homeAnimationCropped}
                                    poster='https://www.witbe.net/assets/2024-hero-desktop-still.jpg'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <section id={heroStyles.darkBlueSection}>
                    <h2 id={heroStyles.blueSectionTitle}>
                        {t('siteMetadata.home.what')}
                    </h2>
                    <div className={heroStyles.blueSectionText} ref={scrollElementReference}>
                        <p>{t('siteMetadata.home.mission1')}</p>
                    </div>
                    {isMobile
                        ? (
                            <ul id={heroStyles.threeColListMobile}>
                                <li className={heroStyles.itemMobile}>
                                    <div className={heroStyles.itemHeadline}>
                                        <h3 className={heroStyles.itemTitle}>
                                            {t('siteMetadata.home2023.stats1')}
                                        </h3>
                                    </div>
                                    <div className={heroStyles.itemText}>
                                        <p className={heroStyles.itemParagraph}>
                                            {t('siteMetadata.home2023.stats1Sub')}
                                        </p>
                                    </div>
                                </li>
                                <li className={heroStyles.itemMobile}>
                                    <div className={heroStyles.itemHeadline}>
                                        <h3 className={heroStyles.itemTitle}>
                                            {t('siteMetadata.home2023.stats2')}
                                        </h3>
                                    </div>
                                    <div className={heroStyles.itemText}>
                                        <p className={heroStyles.itemParagraph}>
                                            {t('siteMetadata.home2023.stats2Sub')}
                                        </p>
                                    </div>
                                </li>
                                <li className={heroStyles.itemMobile}>
                                    <div className={heroStyles.itemHeadline}>
                                        <h3 className={heroStyles.itemTitle}>
                                            {t('siteMetadata.home2023.stats3')}
                                        </h3>
                                    </div>
                                    <div className={heroStyles.itemText}>
                                        <p className={heroStyles.itemParagraph}>
                                            {t('siteMetadata.home2023.stats3Sub')}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        )
                        : (
                            <ul id={heroStyles.threeColList}>
                                <li className={heroStyles.item}>
                                    <div className={heroStyles.itemHeadline}>
                                        <h3 className={heroStyles.itemTitle}>
                                            {t('siteMetadata.home2023.stats1')}
                                        </h3>
                                    </div>
                                    <div className={heroStyles.itemText}>
                                        <p className={heroStyles.itemParagraph}>
                                            {t('siteMetadata.home2023.stats1Sub')}
                                        </p>
                                    </div>
                                </li>
                                <li className={heroStyles.item}>
                                    <div className={heroStyles.itemHeadline}>
                                        <h3 className={heroStyles.itemTitle}>
                                            {t('siteMetadata.home2023.stats2')}
                                        </h3>
                                    </div>
                                    <div className={heroStyles.itemText}>
                                        <p className={heroStyles.itemParagraph}>
                                            {t('siteMetadata.home2023.stats2Sub')}
                                        </p>
                                    </div>
                                </li>
                                <li className={heroStyles.item}>
                                    <div className={heroStyles.itemHeadline}>
                                        <h3 className={heroStyles.itemTitle}>
                                            {t('siteMetadata.home2023.stats3')}
                                        </h3>
                                    </div>
                                    <div className={heroStyles.itemText}>
                                        <p className={heroStyles.itemParagraph}>
                                            {t('siteMetadata.home2023.stats3Sub')}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        )}
                </section>
                <div id='usecase'>
                    <section id={styles.whiteSection}>
                        {isMobile
                            ? <h2 id={styles.whiteSectionTitle} dangerouslySetInnerHTML={{ __html: t('siteMetadata.index.how') }} />
                            : (
                                <h2 id={styles.whiteSectionTitle}>
                                    {t('siteMetadata.index.how')}
                                </h2>
                            )}
                        {/* <div className={styles.whiteSectionText}>
                        <p>{t(`siteMetadata.technology.description3`)}</p>
                    </div> */}
                    </section>
                </div>
                {isMobile
                    ? <ShuffledSectionMobile id={styles.carouselSection} data={carouselDataArray} />
                    : <ShuffledSection id={styles.carouselSection} data={carouselDataArray} />}
                <Customers />
                {isMobile
                    ? (
                        <section id={styles.quadColSectionMobile}>
                            <h2 id={styles.quadColTitleMobile}>
                                {t('siteMetadata.index.empower')}
                            </h2>
                            <ul id={styles.quadColListMobile}>
                                {recentArticles.map(article => (
                                    <Link
                                        to={article.link}
                                        key={article.postId}
                                    >
                                        <li className={styles.itemMobile}>
                                            <div className={styles.mobileCardImageContainer}>
                                                <GatsbyImage
                                                    image={article.imageFooterList}
                                                    alt={article.thumbnailAlt}
                                                    className={styles.cardImageWrapperMobile}
                                                />
                                            </div>
                                            <div className={styles.itemContentMobile}>
                                                <h3 className={styles.itemTitleMobile} dangerouslySetInnerHTML={{ __html: article.titleList }} />
                                                <p className={styles.itemParagraphMobile} dangerouslySetInnerHTML={{ __html: article.subtitleListShort || article.subtitleList }} />
                                                <p className={styles.itemLearnMobile}>
                                                    {t('siteMetadata.index.viewUseCase')}
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        className={styles.chevronRightBlue}
                                                    />
                                                </p>
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </section>
                    )
                    : (
                        <section id={styles.quadColSection}>
                            <h2 id={styles.quadColTitle}>
                                {t('siteMetadata.index.empower')}
                            </h2>
                            <ul id={styles.quadColList}>
                                {recentArticles.map(article => (
                                    <Link
                                        to={article.link}
                                        key={article.postId}
                                    >
                                        <li className={styles.item}>
                                            <div className={styles.itemThumb}>
                                                <GatsbyImage
                                                    image={article.imageFooterList}
                                                    alt={article.thumbnailAlt}
                                                    className={styles.itemIcon}
                                                />
                                            </div>
                                            <div className={styles.itemText}>
                                                <h3 className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: article.titleList }} />
                                                <p className={styles.itemParagraph} dangerouslySetInnerHTML={{ __html: article.subtitleListShort || article.subtitleList }} />
                                                <p className={styles.itemLearnMore}>
                                                    {t('siteMetadata.index.readMore')}
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        className={styles.chevronRight}
                                                    />
                                                </p>
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </section>
                    )}
                <HighlightedArticlesCarousel articles={highlightedArticles} />
                <UpcomingEventsBanner />
            </Layout>
        </React.Fragment>
    );
};

export default Index;
