import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';

import { useViewport } from '../../hooks';
import styles from '../../pages/styles/newHome.module.scss';
import mobileStyles from '../../pages/styles/homeMobile.module.scss';

const propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
};

const HighlightedArticlesCarousel = ({ articles, ...properties }) => {
    const { t } = useTranslation();

    // @FIXME: We can handle responsive with css classes.
    // I copied this mobile / desktop duplication to avoid big refactor.
    const { width } = useViewport();
    const breakpointTablets = 821;

    if (articles.length === 0) {
        return null;
    }

    return (
        <section {...properties} className={styles.secondImageWrapper}>
            {width < breakpointTablets
                ? (
                    <LazyLoad offset={400}>
                        <Carousel
                            showThumbs={false}
                            showArrows
                            showStatus={false}
                            autoPlay
                            interval={6000}
                            stopOnHover
                            centerMode
                            centerSlidePercentage={100}
                            swipeable
                            emulateTouch
                            infiniteLoop={false}
                            selectedItem={0}
                            transitionTime={1000}
                            showIndicators
                            className='home-carousel'
                        >
                            {articles.map(article => (
                                <div className={mobileStyles.mainItemMobileWrapper} key={article.postId}>
                                    <div className={mobileStyles.mainItemMobile}>
                                        <div className={mobileStyles.mainCardImageWrapperMobile}>
                                            <img
                                                src={article.imageHomeCarouselMobile}
                                                alt={article.thumbnailAlt}
                                                className={mobileStyles.coverCarouselMobile}
                                            />
                                        </div>
                                        <div className={mobileStyles.mainItemContentMobile}>
                                            <h3 className={mobileStyles.carouselItemTitleMobile}>
                                                {article.titleList}
                                            </h3>
                                            <p className={mobileStyles.carouselItemSubMobile}>
                                                {article.subtitle}
                                            </p>
                                            <p className={mobileStyles.learnMoreBuyButtons}>
                                                <Link
                                                    to={article.link}
                                                    className={`${mobileStyles.blueButtonMobile} ${mobileStyles.buyButton}`}
                                                >
                                                    {t('siteMetadata.index.readMore')}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </LazyLoad>
                )
                : (
                    <LazyLoad offset={400}>
                        <Carousel
                            showThumbs={false}
                            showArrows
                            showStatus={false}
                            autoPlay
                            interval={6000}
                            stopOnHover
                            centerMode
                            centerSlidePercentage={100}
                            swipeable
                            infiniteLoop
                            selectedItem={0}
                            transitionTime={1500}
                            showIndicators
                            className='home-carousel'
                        >
                            {articles.map(article => (
                                <div className={styles.homeCarouselItem} key={article.postId}>
                                    <img src={article.imageHomeCarouselDesktop} alt={article.alt} className={styles.coverCarousel} />
                                    <div className={styles.carouselItemWrapper}>
                                        <div className={styles.carouselItemText}>
                                            <h3 className={styles.carouselItemTitle}>
                                                {article.titleList}
                                            </h3>
                                            <p className={styles.carouselItemSub}>
                                                {article.subtitle}
                                            </p>
                                            <div className={styles.carouselItemButton}>
                                                <Link
                                                    to={article.link}
                                                    className={styles.carouselBlueButton}
                                                >
                                                    {t('siteMetadata.index.readMore')}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </LazyLoad>
                )}
        </section>
    );
};

HighlightedArticlesCarousel.propTypes = propTypes;
HighlightedArticlesCarousel.defaultProps = {
    articles: [],
};

export default HighlightedArticlesCarousel;
