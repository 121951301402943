/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
/* eslint-disable func-style */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import styles from '../blog/styles/blogFooter.module.scss';
import shuffledStyles from './styles/shuffled.module.scss';
import { useViewport } from '../../hooks';

export default function ShuffledSection({ data }) {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;
    const breakpointTablets = 821;

    const shuffledItems = data;

    function getCurrentItemName(item) {
        return item.name;
    };

    return (
        <>
            <div id={shuffledStyles.secondImageWrapper}>
                {shuffledItems.slice(0, 1)
                    .map(article => {
                        return (
                            <div className={shuffledStyles.secondImageTextWrapper} key={article.itemId}>
                                <div className={shuffledStyles.secondImageRight}>
                                    <div className={shuffledStyles.secondImageRightWrap}>
                                        {width < breakpointTablets ?
                                            <GatsbyImage image={article.thumbImage} alt={article.alt} />
                                        :
                                            <GatsbyImage image={article.bannerImage} alt={article.alt} />
                                        }
                                    </div>
                                </div>
                                <div className={shuffledStyles.secondImageLeft}>
                                    <div className={shuffledStyles.secondImageContent}>
                                        {getCurrentItemName(article) === 'Ad monitoring and matching' ? (
                                            <div className={shuffledStyles.yellowButton}>
                                                {t('siteMetadata.products.newButton')}
                                            </div>
                                        )
                                            :
                                            null
                                        }
                                        <h3 className={shuffledStyles.secondImageTitle} dangerouslySetInnerHTML={{__html: article.title}} />
                                        <p className={shuffledStyles.secondImageSub} dangerouslySetInnerHTML={{__html: article.description}} />
                                        <div className={shuffledStyles.learnMoreBuyButtons}>
                                            <Link
                                                to={article.link}
                                                className={shuffledStyles.blueButton}
                                            >
                                                {t('siteMetadata.index.checkOut')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div id={shuffledStyles.quadColSection}>
                <ul id={shuffledStyles.quadColList}>
                    {shuffledItems.slice(1, 5)
                        .map(article => {
                            return (
                                <Link
                                    to={article.link}
                                    key={article.itemId}
                                >
                                    <li className={shuffledStyles.item}>
                                        <div className={shuffledStyles.itemThumb}>
                                            <GatsbyImage
                                                image={article.thumbImage}
                                                alt={article.alt}
                                                className={styles.itemIcon}
                                            />
                                        </div>
                                        <div className={shuffledStyles.itemText}>
                                            <h3 className={shuffledStyles.itemTitle} dangerouslySetInnerHTML={{__html: article.title}} />
                                            <p className={shuffledStyles.itemParagraph} dangerouslySetInnerHTML={{__html: article.subtitle}} />
                                            <p className={shuffledStyles.itemLearnMore}>
                                                {t('siteMetadata.index.viewUseCase')}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={shuffledStyles.chevronRight}
                                                />
                                            </p>
                                        </div>
                                    </li>
                                </Link>
                            );
                        })}
                </ul>
            </div>
        </>
    );
}
