import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { useEvents, useViewport } from '../../hooks';
import { GTM_KEYS } from '../../constants/googleTagManagerKeys';

import styles from './styles/upcomingEventsBanner.module.scss';
import newHomeStyles from '../../pages/styles/newHome.module.scss';

const { HOME: { VIEW_EVENTS } } = GTM_KEYS;


export const UpcomingEventsBanner = () => {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    const { nextTwoUpcomingEvents } = useEvents();

    if (nextTwoUpcomingEvents.length === 0) {
        return null;
    }

    return (
        <section id={styles.blueSection}>
            <h2 id={styles.blueSectionTitleFullWidth}>
                {t('siteMetadata.events.upcoming')}
            </h2>
            <ul id={styles.quadColList}>
                {
                    nextTwoUpcomingEvents.map(event => (
                        <div className={styles.listItem} key={event.eventId}>
                            <li className={styles.itemText}>
                                <span />
                                <Link to={event.link} className={styles.listItemLinked}>
                                    <h3 className={styles.itemTitle}>{event.name}</h3>
                                    <p className={styles.itemSubtitle}>
                                        {event.formattedDate}
                                        {width < breakpoint ? ' | ' : <br />}
                                        {event.location}
                                    </p>
                                </Link>
                            </li>
                        </div>
                    ))
                }
            </ul>
            <div className={styles.witboxButtons}>
                <Link
                    to='/events/'
                    className={`${newHomeStyles.whiteButton} ${styles.whiteButtonEvents} ${styles.buyButton}`}
                    data-gtm-id={VIEW_EVENTS}
                >
                    {t('siteMetadata.events.viewAll')}
                </Link>
            </div>
        </section>
    );
}
