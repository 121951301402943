/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import styles from '../blog/styles/blogFooter.module.scss';
import shuffledStyles from './styles/shuffled.module.scss';
import { useViewport } from '../../hooks';

export default function ShuffledSectionMobile({ data }) {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;
    const breakpointTablets = 821;

    const shuffledItems = data;

    function getCurrentItemName(item) {
        return item.name;
    };

    return (
        <>
            <div id={shuffledStyles.secondImageWrapper}>
                {shuffledItems.slice(0, 1)
                    .map(article => {
                        return (
                            <div className={shuffledStyles.mainItemMobile} key={article.itemId}>
                                <div className={shuffledStyles.mainCardImageWrapperMobile}>
                                    <GatsbyImage image={article.thumbImage} alt={article.alt} />
                                </div>
                                <div className={shuffledStyles.mainItemContentMobile}>
                                    {getCurrentItemName(article) === 'Ad monitoring' ? (
                                        <div className={shuffledStyles.yellowButton}>
                                            {t('siteMetadata.products.newButton')}
                                        </div>
                                    )
                                        :
                                        null
                                    }
                                    <h3 className={shuffledStyles.mainItemTitleMobile} dangerouslySetInnerHTML={{__html: article.title}} />
                                    <p className={shuffledStyles.mainItemParagraphMobile} dangerouslySetInnerHTML={{__html: article.description}} />
                                    <p className={shuffledStyles.learnMoreBuyButtons}>
                                        <Link
                                            to={article.link}
                                            className={shuffledStyles.blueButtonMobile + ' ' + shuffledStyles.buyButton}
                                        >
                                            {t('siteMetadata.index.checkOut')}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div id={shuffledStyles.quadColSectionMobile}>
                <ul id={shuffledStyles.quadColListMobile}>
                    {shuffledItems.slice(1, 5)
                        .map(article => {
                            return (
                                <Link
                                    to={article.link}
                                    key={article.itemId}
                                >
                                    <li className={shuffledStyles.itemMobile}>
                                        <div className={shuffledStyles.mobileCardImageContainer}>
                                            {/* <LazyLoad offset={100} className={shuffledStyles.cardImageWrapperMobile}>
                                                <img src={article.thumbImage} className={shuffledStyles.cover} alt={article.alt} />
                                            </LazyLoad> */}
                                            <GatsbyImage
                                                image={article.thumbImage}
                                                alt={article.alt}
                                                className={styles.cardImageWrapperMobile}
                                            />
                                        </div>
                                        <div className={shuffledStyles.itemContentMobile}>
                                            <h3 className={shuffledStyles.itemTitleMobile} dangerouslySetInnerHTML={{__html: article.title}} />
                                            <p className={shuffledStyles.itemParagraphMobile} dangerouslySetInnerHTML={{__html: article.subtitle}} />
                                            <p className={shuffledStyles.itemLearnMobile}>
                                                {t('siteMetadata.index.viewUseCase')}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={shuffledStyles.chevronRightBlue}
                                                />
                                            </p>
                                        </div>
                                    </li>
                                </Link>
                            );
                        })}
                </ul>
            </div>
        </>
    );
}
